import React from 'react'
import theme from '../utils/theme'
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../layout/Layout'
import Seo from '../components/Seo'
import { HeroSection, ServiceSection, PortfolioSection, WhyusSection } from '../sections/index'
import { SmallBand, MediumBand } from '../sections/BandSection'

const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {
      portfolioOne: file(relativePath: { eq: "stock/Allency-Caso-de-exito-RSR.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioTwo: file(relativePath: { eq: "stock/Allency-Caso-de-exito-Gigi-Snacks-2.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioThree: file(relativePath: { eq: "stock/Allency-Caso-de-exito-Felah-Room.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      portfolioFour: file(relativePath: { eq: "stock/Allency-Caso-de-exito-Dental-Doctors.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

      whyOne: file(relativePath: { eq: "stock/allency-why-choose-us-estrategy.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyTwo: file(relativePath: { eq: "stock/allency-why-choose-us-results.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyFour: file(relativePath: { eq: "stock/allency-why-choose-us-quality-and-image.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
      whyThree: file(relativePath: { eq: "stock/allency-why-choose-us-work-team.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

    }
  `)

  const portfolioOne = dataImage.portfolioOne.childImageSharp.gatsbyImageData
  const portfolioTwo = dataImage.portfolioTwo.childImageSharp.gatsbyImageData
  const portfolioThree = dataImage.portfolioThree.childImageSharp.gatsbyImageData
  const portfolioFour = dataImage.portfolioFour.childImageSharp.gatsbyImageData

  const whyOne = dataImage.whyOne.childImageSharp.gatsbyImageData
  const whyTwo = dataImage.whyTwo.childImageSharp.gatsbyImageData
  const whyThree = dataImage.whyThree.childImageSharp.gatsbyImageData
  const whyFour = dataImage.whyFour.childImageSharp.gatsbyImageData

  const contents = {
    portfolio: {
      slides: [
        {image: portfolioOne, name: `Run Santa Run`, title: 'Publicidad | Desarrollo web | Mkt digital', description: `La carrera más divertida del año y con el mejor espíritu navideño. Nos sentimos muy orgullosos de ser sus aliados en publicidad, desarrollo de imagen y alcance en medios digitales para la ciudad de Monterrey, Guadalajara, y próximamente Mérida y Querétaro. `, button: 'Ver más', link: '/portfolio/run-santa-run'},
        {image: portfolioTwo, name: `Gigi Snacks`, title: 'Branding', description: `Creamos el branding de la marca junto al diseño de packaging de los productos e implementamos una linea de diseño para la conceptualización de la marca en medios digitales.  `, button: 'Ver más', link: '/portfolio/gigi-snacks'},
        {image: portfolioThree, name: `Felah Room`, title: 'Branding | Mkt digital', description: `Nos encargamos de la construcción de su identidad y comunicación de marca. Con este primer paso, se empiezan a generar los materiales de comunicación de Felah Room para la creación de contenido y brindar alcance de la marca en medios digitales.`, button: 'Ver más', link: '/portfolio/felah-room'},
        {image: portfolioFour, name: `Dental Doctors`, title: 'Mkt digital', description: `En Dental Doctor's nos dimos la tarea de definir estrategias para la creación de contenido, manteniendo a su audiencia activa en redes sociales y generando presencia para la marca.`, button: 'Ver más', link: '/portfolio/dental-doctors'}
      ],
    },
    whyUs: {
      reasons: [
        {image: whyOne, title: `PLAN ESTRATÉGICO`, description: `
          Creemos fielmente que la mejor manera de conseguir resultados es implementando un plan estratégico y llevarlo a la acción.<br/><br/>
          Nos encargaremos de generarte un plan con las estrategias de publicidad adecuadas para generar resultados integrales en tu proyecto.`, button: 'Saber más', link: '/about-us'},
        {image: whyTwo, title: `RESULTADOS`, description: `
          Estamos comprometidos con tu marca, no hay mejor satisfacción que dejar que los resultados hablen por sí solos.<br/><br/>
          En tu proyecto, le daremos seguimiento a los resultados a través de reportes e indicadores clave de rendimiento.`, button: 'Saber más', link: '/about-us'},
        {image: whyThree, title: `EQUIPO DE TRABAJO`, description: `
          Durante el proyecto contarás con el apoyo de un equipo multidisciplinario que brindará la pasión y resultados que buscas para tu negocio.<br/><br/>
          Trabajarás de la mano con encargados de nuestras áreas de gestión de proyectos, marketing, programación web y diseño gráfico.`, button: 'Saber más', link: '/about-us'},
        {image: whyFour, title: `CALIDAD E IMAGEN`, description: `
          La imagen es importante, por eso buscamos entregar trabajos de primera, revisando hasta los más pequeños detalles.<br/><br/>
          Tendrás el apoyo de expertos en brindarle una imagen profesional a tu marca, transmitir el mensaje de manera correcta y cumplir con todos los objetivos de comunicación.`, button: 'Saber más', link: '/about-us'}
      ]
    }
  }

  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Agencia de publicidad - Soluciones integrales de publicidad y marketing para tu negocio'}
        description={"Generamos soluciones que van de acuerdo a las necesidades de tu negocio para tu estrategia publicitaria."}
        keywords={['Agencia de publicidad,Publicidad,Aliados,Estrategia,Marketing,Agencia,Digital,Resultados,Branding,Programación,Código,Diseño,Proyecto,Planes,Soluciones,Calidad,Gestión,Campañas']}
        noIndex={''}
        noFollow={''}
        slug={'main-page'}
        canonicalUrl={'https://allency.com.mx'}
        ogTitle={'Agencia de publicidad - Soluciones integrales de publicidad y marketing para tu negocio'}
        ogType={'website'}
        ogDescription={"Generamos soluciones que van de acuerdo a las necesidades de tu negocio para tu estrategia publicitaria."}
        ogImage={'../../images/logos/allency-dark.png'}
      />
      
      <HeroSection />
      <ServiceSection />
      <MediumBand bg={theme.colors.extended.gray900} title={`Hagamos de tu <br/> proyecto una realidad`} description={'Soluciones integrales de publicidad'} colorDescription={theme.colors.primary} button={'Comencemos tu proyecto'} buttonVariant={'button-light-ghost'} link={'/contact'} />
      <PortfolioSection contents={contents.portfolio}/>
      <SmallBand />
      <WhyusSection contents={contents.whyUs} />
    </Layout>
  )
}

export default IndexPage